import React from 'react';
import styled from 'styled-components';
import {ContactInfo} from '../section';

const HeaderContainer = styled.header`
  padding: 3rem 6rem;
  border-bottom: 2px solid currentColor;

  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-areas:
    'name contact'
    'role contact';
  align-items: flex-end;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'name'
      'role'
      'contact';
  }
`;

const PageTitle = styled.h1`
  font-size: 5rem;
  grid-area: name;
`;

const PageSubTitle = styled.h2`
  font-size: 2.5rem;
  grid-area: role;
`;

const StyledContactInfo = styled(ContactInfo)`
  grid-area: contact;

  @media (max-width: 900px) {
    opacity: 0.8;
    margin-top: 3rem;
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <PageTitle>Nahum Mizrahi</PageTitle>
      <PageSubTitle>Full Stack Web Developer</PageSubTitle>
      <StyledContactInfo />
    </HeaderContainer>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
