import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SVG_ICON_TYPE = {
  phone: 'phone',
  emailOutline: 'emailOutline',
  info: 'info',
  infoOutline: 'infoOutline'
};

const viewBox = {
  [SVG_ICON_TYPE.phone]: '0 0 28 28',
  [SVG_ICON_TYPE.emailOutline]: '0 0 33.6 28',
  [SVG_ICON_TYPE.info]: '0 0 24 24',
  [SVG_ICON_TYPE.infoOutline]: '0 0 24 24'
};

const style = {};

const content = {
  [SVG_ICON_TYPE.phone]: (
    <path d="M7.424,12.521a18.452,18.452,0,0,0,8.054,8.054l2.689-2.689a1.227,1.227,0,0,1,1.247-.306,13.885,13.885,0,0,0,4.363.7A1.222,1.222,0,0,1,25,19.5v4.278A1.222,1.222,0,0,1,23.778,25,20.778,20.778,0,0,1,3,4.222,1.222,1.222,0,0,1,4.222,3H8.5A1.222,1.222,0,0,1,9.722,4.222a13.885,13.885,0,0,0,.7,4.363,1.227,1.227,0,0,1-.306,1.247Z" />
  ),
  [SVG_ICON_TYPE.emailOutline]: (
    <path d="M31.6,6.5A2.768,2.768,0,0,0,28.64,4H4.96A2.768,2.768,0,0,0,2,6.5v15A2.768,2.768,0,0,0,4.96,24H28.64a2.768,2.768,0,0,0,2.96-2.5V6.5m-2.96,0L16.8,12.75,4.96,6.5H28.64m0,15H4.96V9L16.8,15.25,28.64,9Z" />
  ),
  [SVG_ICON_TYPE.info]: (
    <path d="M13,9H11V7h2m0,10H11V11h2M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Z" />
  ),
  [SVG_ICON_TYPE.infoOutline]: (
    <path d="M11,9h2V7H11m1,13a8,8,0,1,1,8-8,8.011,8.011,0,0,1-8,8M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2M11,17h2V11H11Z" />
  )
};

const Svg = styled.svg`
  fill: currentColor;
  ${p => style[p.type]};
`;

const SvgIcon = ({className, type, ...rest}) => {
  return (
    <Svg
      className={className}
      type={type}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox[type]}
      {...rest}>
      {content[type]}
    </Svg>
  );
};

SvgIcon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(SVG_ICON_TYPE)).isRequired
};

SvgIcon.defaultProps = {
  className: ''
};

export {SVG_ICON_TYPE, SvgIcon as default};
