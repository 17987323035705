import React from 'react';
import styled from 'styled-components';

import myInfo from '../../data/myInfo';
import JobItem from '../job/JobItem';

const JobsContainer = styled.div``;

const Jobs = () => {
  const {jobs} = myInfo;
  return (
    <JobsContainer>
      {jobs.map(job => (
        <JobItem key={job.company} job={job} />
      ))}
    </JobsContainer>
  );
};

export default Jobs;
