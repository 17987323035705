import React from 'react';
import styled, {css, keyframes} from 'styled-components';
import PropTypes from 'prop-types';

import {rgba} from 'polished';
import myInfo from '../../data/myInfo';

const SkillsContainer = styled.div``;

const MainSkills = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 1rem;
  align-items: center;
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin-bottom: 2rem;
`;

const SecondarySkills = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 2rem;
  line-height: 2rem;
  gap: 1rem 2rem;
`;

const SkillName = styled.p`
  text-transform: capitalize;

  ${MainSkills} & {
    margin-right: 1rem;
  }

  ${SecondarySkills} & {
    background-color: transparent;

    &:hover {
      background-color: ${p => rgba(p.theme.color.primary1, 0.2)};
    }
  }
`;

const scoreAnimation = score => keyframes`
  0% {
    width: 0%;
  }

  100% {
    width: ${(score / 10) * 100}%;
  }
`;

const SkillScore = styled.span`
  ${p => css`
    position: relative;
    display: block;
    height: 1rem;
    width: 25rem;
    background-color: ${p.theme.color.gray};

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: currentColor;

      animation-name: ${scoreAnimation(p.skillScore)};
      animation-timing-function: linear;
      animation-duration: ${(p.skillScore / 10) * 2}s;
      animation-fill-mode: forwards;
    }
  `}
`;

const SkillScoreValue = styled.span`
  font-size: 1.5rem;
`;

const Skills = ({className}) => {
  const {
    skills: {mainSkills, secondarySkills}
  } = myInfo;
  return (
    <SkillsContainer>
      <MainSkills className={className}>
        {mainSkills.map(({name: skillName, score: skillScore}) => (
          <React.Fragment key={skillName}>
            <SkillName>{skillName}</SkillName>
            <SkillScore skillScore={skillScore} />
            <SkillScoreValue>{skillScore}</SkillScoreValue>
          </React.Fragment>
        ))}
      </MainSkills>
      <SecondarySkills>
        {secondarySkills.map(skill => (
          <SkillName key={skill}>{skill}</SkillName>
        ))}
      </SecondarySkills>
    </SkillsContainer>
  );
};

Skills.propTypes = {
  className: PropTypes.string
};

Skills.defaultProps = {
  className: ''
};

export default Skills;
