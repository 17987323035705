import React from 'react';
import styled from 'styled-components';

import myInfo from '../data/myInfo';
import Section from '../components/section/Section';
import {Jobs, Skills, ProfessionalCourses} from '../components/section';

const HomePageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10rem;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: initial;
  }
`;

const Column = styled.div`
  overflow: auto;
  max-width: 85rem;

  @media (max-width: 900px) {
    overflow: visible;
  }
`;
const LeftColumn = styled(Column)`
  flex: 2;
`;

const RightColumn = styled(Column)`
  flex: 1;
  min-width: 50rem;
`;

const LanguageItem = styled.div``;

const HomePage = () => {
  const {aboutMe, education: myEducation, languages} = myInfo;
  return (
    <HomePageContainer>
      <LeftColumn>
        <Section title="Who Am I">{aboutMe}</Section>
        <Section title="Job Experience">
          <Jobs />
        </Section>
      </LeftColumn>
      <RightColumn>
        <Section title="Skills">
          <Skills />
        </Section>
        <Section title="Education">{myEducation}</Section>
        <Section title="Professional Courses">
          <ProfessionalCourses />
        </Section>
        <Section title="Languages">
          {languages.map(lang => (
            <LanguageItem key={lang.type}>{`${lang.type}: ${lang.level}`}</LanguageItem>
          ))}
        </Section>
      </RightColumn>
    </HomePageContainer>
  );
};

export default HomePage;
