const aboutMe =
  'I am a passionate full stack developer, with over 5 years of web development experience working with the latest web platforms and recent technologies,';

const ePlatform = {
  html: 'HTML',
  css: 'CSS',
  java: 'java',
  javascript: 'javascript',
  nodejs: 'nodejs',
  reactjs: 'reactjs',
  redux: 'redux',
  recoil: 'recoil',
  es: 'elastic search',
  aws: 'AWS',
  backbone: 'backbone',
  php: 'php',
  wordpress: 'wordpress',
  jquery: 'jQuery',
  angularjs: 'angularjs',
  angular2: 'angular2',
  cSharp: 'C#',
  dotnet: 'dot.Net',
  vba: 'VBA [VB6]',
  sql: 'SQL',
  mysql: 'mysql',
  plsql: 'Oracle PL/SQL',
  mongodb: 'MongoDB',
  telerik: 'Telerik',
  unix: 'Unix',
  linux: 'Linux',
  ajax: 'Ajax',
  docker: 'Docker',
  ci_cd: 'CI/CD pipelines',
  git: 'Git'
};

const skills = {
  mainSkills: [
    {name: ePlatform.html, score: 10},
    {name: ePlatform.css, score: 10},
    {name: ePlatform.reactjs, score: 10},
    {name: ePlatform.nodejs, score: 9},
    {name: ePlatform.sql, score: 10},
    {name: ePlatform.mongodb, score: 7},
    {name: ePlatform.aws, score: 5}
  ],
  secondarySkills: [
    ePlatform.cSharp,
    ePlatform.javascript,
    ePlatform.redux,
    ePlatform.recoil,
    ePlatform.jquery,
    ePlatform.ajax,
    ePlatform.angularjs,
    ePlatform.php,
    ePlatform.wordpress,
    ePlatform.es,
    ePlatform.docker,
    ePlatform.ci_cd,
    ePlatform.git,
    'Cognito',
    'mailChimp',
    'mailGun',
    'Slack',
    'Facebook Api',
    'SSM',
    'Kibana',
    'CloudWatch'
  ]
};

const jobs = [
  {
    company: 'Voyager Labs',
    companyDescription:
      'Voyager Labs, a world leader in advanced AI-based investigation solutions, empowers organizations worldwide to gain deep investigative insights about entities, individuals, groups, and topics.',
    siteUrl: 'https://voyagerlabs.co/',
    period: {from: '2020', to: '2021'},
    role: 'Full stack web developer [Java]',
    jobFunctions: [
      'Design and update the voyager apps features',
      'Converting backbone based components to react components',
      'Legacy code maintenance (Backbone)',
      'Working with micro-services and developing java api'
    ],
    platforms: [ePlatform.java, ePlatform.reactjs, ePlatform.redux, ePlatform.backbone]
  },
  {
    company: 'Novarize',
    companyDescription: `Novarize is an innovative hi-tech firm that is reshaping the ways businesses and consumers connect by building a groundbreaking audience platform that bypasses the data limitations imposed on brands.`,
    siteUrl: 'https://www.novarize.com/',
    period: {from: '2019', to: '2020'},
    role: 'Full stack web developer [Node]',
    jobFunctions: [
      'Design and update company pages and systems including the marketing site, BackOffice and WebApp.',
      'Creatively utilize ReactHooks, NodeJS, Redux, MySQL, Elastic Search, and Kafka technologies.',
      'Perform integration with external services using MailChimp, MailGun, Slack, and Facebook API.',
      'Write CI/CD Pipelines, and execute work using Docker, Git, Cognito, AWS, SSM, Kibana, and CloudWatch.',
      'Drive projects from inception through delivery.',
      'Work in cross-functional teams on dynamic products and take the lead in all stages of development, from software design to implementation and testing.',
      'Oversee automated testing and provide feedback to management during development.',
      'Modify and test changes to previously developed programs.'
    ],
    platforms: [
      ePlatform.nodejs,
      ePlatform.reactjs,
      ePlatform.redux,
      ePlatform.mysql,
      ePlatform.es,
      ePlatform.aws,
      ePlatform.php,
      ePlatform.wordpress
    ]
  },
  {
    company: 'Coolvision',
    companyDescription:
      'Coolvision is part of a group developing online video streaming platforms, used by millions of users across the globe.',
    siteUrl: 'https://www.coolvision.co.il/',
    period: {from: '2015', to: '2019'},
    role: 'Full stack web developer [ASP dot.net]',
    jobFunctions: [
      'Developed the company’s web page.',
      'Developed web pages for over 1000 different private and public corporations.',
      'Managed the end-to-end lifecycle of the production of software and applications.'
    ],
    platforms: [ePlatform.jquery, ePlatform.angularjs, ePlatform.cSharp]
  },
  {
    company: 'Partner communications [Orange Israel]',
    companyDescription: 'Partner Communications is Israel’s leading telecommunications company.',
    siteUrl: 'https://www.partner.co.il/',
    period: {from: '2007', to: '2015'},
    role: [
      {
        title: 'CRM and web developer',
        period: {from: '2013', to: '2015'}
      },
      {
        title: 'QA',
        period: {from: '2010', to: '2013'}
      },
      {
        title: 'Service Representive',
        period: {from: '2007', to: '2010'}
      }
    ],
    jobFunctions: [
      'Developed the CRM system for Vantive-VBA.',
      'Developed and wrote scripts on PL and SQL in Oracle.',
      'Created dot.Net, client-side, and server-side interfaces, using Telerik.',
      'Tested Partner’s CRM systems and dot.Net interfaces.',
      'Performed manual E2E tests for functionality and GUI.',
      'Provided practical advice to clients on the proper use and upkeep of devices.',
      'Provided technical support and an answering service to other service representatives in the company.'
    ],
    platforms: [ePlatform.cSharp, ePlatform.vba, ePlatform.plsql, ePlatform.telerik]
  }
];

const professionalCourses = [
  {
    institution: 'John Brice',
    courses: [
      ePlatform.sql,
      ePlatform.plsql,
      ePlatform.cSharp,
      ePlatform.dotnet,
      ePlatform.unix,
      ePlatform.linux
    ]
  },
  {
    institution: 'Sela Academy',
    courses: [ePlatform.angular2]
  },
  {
    institution: '500 Tech',
    courses: [ePlatform.reactjs]
  }
];

const languages = [
  {
    type: 'Hebrew',
    level: 'Native'
  },
  {
    type: 'English',
    level: 'Very Proficient'
  }
];

const myInfo = {
  aboutMe,
  phoneNumber: '+972-54-243-8684',
  emailAddress: 'nahum.mizrahi2@gmail.com',
  education: 'Bachelor of Computer Science and Mathematics, Ariel University 2012',
  professionalCourses,
  skills,
  jobs,
  languages
};

export default myInfo;
