import React from 'react';
import styled, {keyframes} from 'styled-components';
import PropTypes from 'prop-types';
import {rgba} from 'polished';

const textAnimation = (startTop, startLeft) => keyframes`
from{
  top: ${startTop};
  left: ${startLeft};
  opacity: 0;
}
  to {
    top: 0px;
    left: 0px;
    opacity: 1;
  }
`;

const SectionContainer = styled.section`
  background-color: transparent;
  padding: 3rem;
  font-size: 2.5rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
  cursor: default;

  &:hover {
    background-color: ${p => rgba(p.theme.color.primary1, 0.1)};
  }
`;

const SectionTitle = styled.h4`
  font-size: 4.5rem;
  line-height: 4.5rem;
  opacity: 0.4;
  margin-bottom: 2rem;
`;

const TitleLetter = styled.span`
  top: ${p => p.top};
  left: ${p => p.left};
  opacity: 0;
  position: relative;
  animation-name: ${p => textAnimation(p.top, p.left)};
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  will-change: top, left;
`;

const SectionContent = styled.div`
  padding-left: 3rem;
`;

const getRandomPosition = () => {
  const left = window.innerWidth * Math.random();
  const top = window.innerHeight * Math.random();
  return {
    left: Math.random() < 0.5 ? `${left * -1}px` : `${left}px`,
    top: Math.random() < 0.5 ? `${top * -1}px` : `${top}px`
  };
};

const Section = ({className, title, children}) => {
  return (
    <SectionContainer className={className}>
      <SectionTitle>
        {title.split('').map((letter, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <TitleLetter key={`${letter}_${i}`} {...getRandomPosition()}>
            {letter}
          </TitleLetter>
        ))}
      </SectionTitle>
      <SectionContent>{children}</SectionContent>
    </SectionContainer>
  );
};

Section.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

Section.defaultProps = {
  className: ''
};

export default Section;
