import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';

import AppModule from './modules/App';
import normalize from './theme/normalize';

const GlobalStyles = createGlobalStyle`
    ${normalize};
`;

function App() {
  return (
    <>
      <GlobalStyles />
      <AppModule />
    </>
  );
}

ReactDOM.render(<App />, document.getElementById('app'));
