import {css} from 'styled-components';
import {color, font} from './index';

export default css`
  @import url('https://fonts.googleapis.com/css2?family=Acme&family=Noto+Sans+SC:wght@300;400;500;700&display=swap');

  *,
  *:after,
  *:before {
    box-sizing: border-box;
    font: inherit;
    font-size: 1em;
    outline: none;
  }

  html {
    font-size: 8px;
    /* font-size: 2.225vw;

    @media only screen and (min-width: 420px) {
      font-size: 1.905vw;
    }

    @media only screen and (min-width: 1024px) {
      font-size: 8px;
    } */
  }

  html,
  body {
    height: 100vh;
    color: ${color.primary1};
    background-color: ${color.siteBackground};
    font-family: 'Myanmar MN';
    font-weight: ${font.notoSansSC.weight.regular};
  }

  body {
    font-size: 2rem;
    -webkit-font-smoothing: antialiased;
  }

  body,
  ul,
  ol,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul,
  ol {
    list-style-type: none;
  }

  svg {
    display: block;
  }

  #app {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
`;
