import React from 'react';
import styled from 'styled-components';

import myInfo from '../../data/myInfo';

const ProfessionalCoursesContainer = styled.div``;
const CoursesContainer = styled.ul`
  font-size: 1.7rem;
  list-style: circle;
  margin-left: 4rem;
  margin-bottom: 2rem;
`;
const InstitutionName = styled.p``;
const CourseName = styled.li``;

const ProfessionalCourses = () => {
  const {professionalCourses} = myInfo;
  return (
    <ProfessionalCoursesContainer>
      {professionalCourses.map(({institution, courses}) => (
        <React.Fragment key={institution}>
          <InstitutionName>{institution}</InstitutionName>
          <CoursesContainer>
            {courses.map(course => (
              <CourseName key={course}>{course}</CourseName>
            ))}
          </CoursesContainer>
        </React.Fragment>
      ))}
    </ProfessionalCoursesContainer>
  );
};

export default ProfessionalCourses;
