import React from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';

import Tooltip from '../../elements/popups/Tooltip';

import SvgIcon, {SVG_ICON_TYPE} from '../../elements/buttons/icons/SvgIcon';

const JobItemContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 4rem;
  }
`;
const JobHeader = styled.div`
  display: flex;
  align-items: center;
`;

const CompanyName = styled.a`
  color: currentColor;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  position: relative;
  font-size: 3rem;

  ${p =>
    p.href &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
`;
const Period = styled.p`
  margin-left: auto;
  text-align: right;
  font-size: 1.8rem;
`;

const JobContent = styled.div`
  margin-left: 3rem;
`;

const RolesContainer = styled.div`
  margin-bottom: 1rem;
`;

const Role = styled.p`
  font-size: 2.2rem;
  opacity: 0.7;
`;

const InfoIcon = styled(SvgIcon).attrs({type: SVG_ICON_TYPE.infoOutline})`
  position: absolute;
  top: 0;
  left: 100%;
  transform: translateX(0.5rem);
  height: 2rem;
  width: auto;
`;

const FunctionsContainer = styled.ul`
  font-size: 1.7rem;
  list-style: circle;
  margin-left: 4rem;
  margin-bottom: 2rem;
`;

const FunctionItem = styled.li``;

const PlatformsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

const PlatformItem = styled.span`
  display: block;
  color: ${p => p.theme.color.primary1};
  background-color: transparent;
  border: 1px solid ${p => p.theme.color.primary1};
  font-size: 2rem;
  line-height: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  text-transform: lowercase;
  opacity: 0.7;
  transition: all 0.3s ease-in-out;

  color: ${p => p.theme.color.primary1};
  background-color: ${p => p.theme.color.primary2};

  &:hover {
    color: ${p => p.theme.color.primary2};
    background-color: ${p => p.theme.color.primary1};
  }
`;

const JobItem = ({job}) => {
  const {
    company,
    companyDescription,
    siteUrl,
    period,
    role: jobRole,
    jobFunctions,
    platforms
  } = job;

  return (
    <>
      <JobItemContainer>
        <JobHeader>
          <CompanyName href={siteUrl} target="_blank">
            {company}
            {companyDescription && (
              <InfoIcon data-for="company-desc-tooltip" data-tip={companyDescription} />
            )}
          </CompanyName>
          <Period>{`${period.from}-${period.to}`}</Period>
        </JobHeader>
        <JobContent>
          <RolesContainer>
            {Array.isArray(jobRole) ? (
              jobRole.map(role => (
                <Role
                  key={role.title}>{`${role.title}\t(${role.period.from}-${role.period.to})`}</Role>
              ))
            ) : (
              <Role>{jobRole}</Role>
            )}
          </RolesContainer>
          <FunctionsContainer>
            {jobFunctions.map(item => (
              <FunctionItem key={item}>{item}</FunctionItem>
            ))}
          </FunctionsContainer>
          <PlatformsContainer>
            {platforms.map(platform => (
              <PlatformItem key={platform}>{platform}</PlatformItem>
            ))}
          </PlatformsContainer>
        </JobContent>
      </JobItemContainer>
      <Tooltip id="company-desc-tooltip" place="right" />
    </>
  );
};

JobItem.propTypes = {
  job: PropTypes.shape({
    company: PropTypes.string.isRequired,
    companyDescription: PropTypes.string,
    siteUrl: PropTypes.string,
    period: PropTypes.shape({from: PropTypes.string, to: PropTypes.string}),
    role: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          period: PropTypes.shape({
            from: PropTypes.string,
            to: PropTypes.string
          })
        })
      )
    ]).isRequired,
    jobFunctions: PropTypes.arrayOf(PropTypes.string),
    platforms: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

JobItem.defaultProps = {};

export default JobItem;
