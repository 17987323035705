import React from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import SvgIcon, {SVG_ICON_TYPE} from '../../elements/buttons/icons/SvgIcon';
import myInfo from '../../data/myInfo';

const iconStyle = css`
  height: 2.5rem;
  width: auto;
`;

const ContactInfoContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 1rem;
  align-items: center;
  justify-items: flex-start;
`;
const PhoneIcon = styled(SvgIcon).attrs({type: SVG_ICON_TYPE.phone})`
  ${iconStyle};
`;

const EmailIcon = styled(SvgIcon).attrs({type: SVG_ICON_TYPE.emailOutline})`
  ${iconStyle};
`;

const ContactLink = styled.a`
  color: currentColor;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  line-height: 1em;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-bottom-color: currentColor;
  }
`;

const ContactInfo = ({className}) => {
  const {phoneNumber, emailAddress} = myInfo;
  return (
    <ContactInfoContainer className={className}>
      <PhoneIcon />
      <ContactLink href={`tel:${phoneNumber}`}>{phoneNumber}</ContactLink>
      <EmailIcon />
      <ContactLink href={`mailto:${emailAddress}`}>{emailAddress}</ContactLink>
    </ContactInfoContainer>
  );
};

ContactInfo.propTypes = {
  className: PropTypes.string
};

ContactInfo.defaultProps = {
  className: ''
};

export default ContactInfo;
