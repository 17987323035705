import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';

import theme from '../theme';
import Header from '../components/header/Header';
import HomePage from '../pages/HomePage';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${p => p.theme.color.siteBackground};
  max-width: 1400px;
  margin: 0 auto;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  overflow: auto;
  margin: 2.5rem 0;
  padding: 0 6rem;
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <BrowserRouter>
          <Header />
          <AppContent>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Redirect to="/" />
            </Switch>
          </AppContent>
        </BrowserRouter>
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
