import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import {rgba} from 'polished';

const Tooltip = styled(ReactTooltip)`
  color: ${p => p.theme.color.darkGray} !important;
  background-color: ${p => rgba(p.theme.color.white, 0.3)} !important;

  &:after {
    border-right-color: ${p => rgba(p.theme.color.white, 0.3)} !important;
  }
`;

export default Tooltip;
