const transparent = 'transparent';
const currentColor = 'currentColor';

const white = 'white';
const black = '#000';

const gray400 = '#707070';
const gray600 = '#333';
const darkYellow = '#B6CB72';

const color = {
  transparent,
  currentColor,
  white,
  black,
  gray: gray400,
  darkGray: gray600,
  primary1: darkYellow,
  primary2: gray600,
  siteBackground: gray600
};

export default color;
