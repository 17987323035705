const font = {
  // EXAMPLE
  acme: {
    family: 'Acme, sans-serif',
    weight: {
      light: '300',
      regular: '400',
      medium: '500',
      bold: '700'
    }
  },
  // EXAMPLE
  notoSansSC: {
    family: "'Noto Sans SC', sans-serif",
    weight: {
      light: '300',
      regular: '400',
      medium: '500',
      bold: '700'
    }
  }
};

export default font;
